<template>
  <div
    v-if="dialog"
    id="OrderSearchDialog"
    class="fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center"
  >
    <div
      class="w-full md:h-auto h-full md:max-w-xl max-h-screen overflow-y-auto relative z-[41] bg-zinc-800 shadow-2xl text-white md:py-12 py-8 px-8 flex flex-col"
    >
      <div class="w-full mb-10">
        <h2
          class="overflow-hidden text-2xl font-black text-white lg:text-5xl sm:text-2xl"
        >
          <span class="block">ORDER SEARCH</span>
        </h2>
        <h2 class="overflow-hidden text-xl font-bold text-primary xl:text-2xl">
          <span class="block">訂單查詢</span>
        </h2>
      </div>
      <div class="flex-1 w-full -mx-2">
        <div class="flex flex-wrap">
          <div class="w-full mb-4 input_group md:w-1/2 md:px-2">
            <p class="mb-2 text-sm text-zinc-400">訂購人姓名</p>
            <input
              type="text"
              name="name"
              v-model="order_data.name"
              class="block w-full px-2 py-2 text-white focus:outline-none bg-zinc-900"
            />
          </div>
          <div class="w-full mb-4 input_group md:w-1/2 md:px-2">
            <p class="mb-2 text-sm text-zinc-400">訂購電話</p>
            <input
              type="tel"
              name="phone"
              v-model="order_data.phone"
              class="block w-full px-2 py-2 text-white focus:outline-none bg-zinc-900"
            />
          </div>
          <div class="w-full mb-4 input_group md:px-2">
            <p class="mb-2 text-sm text-zinc-400">訂購信箱</p>
            <input
              type="email"
              name="email"
              v-model="order_data.email"
              class="block w-full px-2 py-2 text-white focus:outline-none bg-zinc-900"
            />
          </div>
          <div class="w-full mb-4 input_group md:px-2">
            <p class="mb-2 text-sm text-zinc-400">訂單編號</p>
            <input
              type="text"
              name="trade_id"
              v-model="order_data.trade_id"
              class="block w-full px-2 py-2 text-white focus:outline-none bg-zinc-900"
            />
          </div>
        </div>
      </div>

      <div class="flex justify-end w-full md:px-4">
        <button
          @click="Cancel"
          class="inline-block px-8 py-2 mr-2 text-base font-bold transition-colors duration-500 border text-zinc-300 border-zinc-300 md:hover:bg-zinc-400 md:hover:text-black"
        >
          CANCEL
        </button>
        <button
          class="inline-block px-8 py-2 text-base font-bold transition-colors duration-500 border text-primary border-primary md:hover:bg-primary md:hover:text-black"
          @click="Search"
        >
          SUBMIT
        </button>
      </div>
    </div>
    <div
      class="absolute top-0 bottom-0 left-0 right-0 z-40 bg-black opacity-70"
    ></div>
  </div>
</template>

<script>
import { SearchOrder } from '@/api/shop_cart';
export default {
  name: 'OrderSearchDialog',
  data() {
    return {
      order_data: {
        name: '',
        phone: '',
        email: '',
        trade_id: '',
      },
    };
  },
  methods: {
    Cancel() {
      this.$store.commit('SetOrderSearchDialog', false);
      this.order_data = {
        name: '',
        phone: '',
        email: '',
        order_no: '',
      };
    },
    Search() {
      SearchOrder(this.order_data).then((res) => {
        if (res.msg == '查無訂單') {
          this.$store.commit('SetMainDialog', {
            msg: '查無訂單',
            action: true,
          });
        } else {
          this.order_data = {
            name: '',
            phone: '',
            email: '',
            trade_id: '',
          };
          this.$store.commit('SetOrderSearchDialog', false);
          this.$store.commit('SaveOrderResult', res.data);
          this.$router.push('/order_search');
        }
      });
    },
  },
  computed: {
    dialog() {
      return this.$store.state.order_search_dialog;
    },
  },
};
</script>
