<template>
  <section ref="MainContent" id="HomeNews" class="mt-20">
    <div
      class="relative z-10 w-full max-w-screen-xl px-5 mx-auto xl:px-0 sm:px-10"
    >
      <div class="flex items-center justify-between mb-10">
        <div class="">
          <p class="mb-2 text-4xl font-bold text-primary">NEWS</p>
          <h3 class="text-5xl font-bold text-white">最新消息</h3>
        </div>

        <div class="flex items-center">
          <button
            @click="PrevList"
            title="上一則"
            class="flex items-center justify-center w-10 h-10 mr-5 transition-all duration-300 bg-black border border-black bg-opacity-40 text-primary md:hover:bg-primary md:hover:text-black"
          >
            <span class="icon-chevron_left"></span>
          </button>
          <button
            @click="NextList"
            title="下一則"
            class="flex items-center justify-center w-10 h-10 transition-all duration-300 bg-black border border-black bg-opacity-40 text-primary md:hover:bg-primary md:hover:text-black"
          >
            <span class="icon-chevron_right"></span>
          </button>
        </div>
      </div>

      <ol class="-mx-4 whitespace-nowrap gsap_item">
        <li
          v-for="item in news_data"
          :key="`news_list_1_${item.news_id}`"
          data-news-item
          class="relative inline-block w-full px-4 md:w-1/2 lg:w-1/3 md:px-4"
        >
          <router-link
            :to="`/news/page/${item.news_id}`"
            class="relative block w-full overflow-hidden border border-black image_box aspect-h-9 aspect-w-16"
          >
            <img
              width="403"
              height="228"
              :src="item.cover_image"
              :alt="item.title"
              class="block object-cover w-full h-full"
            />
            <span
              class="time_line absolute top-auto bottom-0 left-0 w-full h-1 bg-primary z-10 transform -translate-x-[105%]"
            ></span>
          </router-link>
          <div
            class="flex flex-wrap items-center justify-between mt-2 md:flex-nowrap"
          >
            <div class="w-full text-center md:w-auto md:text-left">
              <router-link
                class="block mb-2"
                :to="`/news/page/${item.news_id}`"
              >
                <h3 class="text-xl font-bold text-white">{{ item.title }}</h3>
              </router-link>
              <div class="flex items-center">
                <p
                  class="px-2 py-1 mr-4 text-xs font-bold text-white border border-black bg-primary"
                >
                  {{ item.category }}
                </p>
                <p class="text-white">
                  {{ item.created_at.slice(0, 10) }}
                </p>
              </div>
            </div>
          </div>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
import { home_news } from '@/gsap/home/news';
export default {
  name: 'HomeNews',
  data() {
    return {
      home_news_animation: null,
    };
  },
  methods: {
    SetGsap() {
      this.home_news_animation = new home_news(this.$refs.MainContent);
    },
    NextList() {
      this.home_news_animation.NextList();
    },
    PrevList() {
      this.home_news_animation.PrevList();
    },
  },
  computed: {
    news_data() {
      return this.$store.state.news_data;
    },
  },
  beforeDestroy() {
    this.home_news_animation.destroy();
  },
};
</script>
