<template>
  <section id="HomeProductCategory" class="mt-20">
    <div
      class="relative z-10 w-full max-w-screen-xl px-5 mx-auto sm:px-10 xl:px-0"
    >
      <div class="">
        <p class="text-4xl font-bold text-primary">COLLECTIONS</p>
        <h3 class="my-4 text-5xl font-bold text-white">商品分類</h3>
      </div>
      <ol class="flex flex-wrap mt-10 -mx-2">
        <li
          class="relative block w-full px-2 mb-4 md:w-1/2 lg:w-1/4"
          v-for="(item, item_index) in product_category"
          :key="item.id"
        >
          <router-link
            class="relative block w-full aspect-1"
            :to="item.link"
            @mouseover.native="hover_item = item_index"
            @mouseleave.native="hover_item = -1"
          >
            <div
              class="absolute z-[11] top-0 left-0 right-0 bottom-0 bg-black bg-opacity-70 flex items-center justify-center"
            >
              <div
                class="relative z-[11] text-center transition-all duration-300 transform px-5"
                :class="hover_item == item_index ? 'scale-110' : ''"
              >
                <h3 class="text-4xl font-bold text-white lg:text-3xl">
                  {{ item.title }}
                </h3>
                <p class="font-bold text-primary">{{ item.sub_title }}</p>
              </div>
            </div>
            <div class="w-full overflow-hidden">
              <img
                width="300"
                height="300"
                :src="item.image"
                :alt="item.title"
                :class="hover_item == item_index ? 'blur-sm ' : ''"
                class="object-cover object-center w-full h-full transition-all duration-500"
              />
            </div>
          </router-link>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'HomeProductCategory',

  data() {
    return {
      hover_item: -1,
    };
  },
  computed: {
    ...mapGetters(['get_common_column']),
    product_category() {
      const tmp_data = this.$store.state.common_column_data;
      if (tmp_data == null) {
        return [];
      } else {
        return [
          this.get_common_column('home_product_category_1'),
          this.get_common_column('home_product_category_2'),
          this.get_common_column('home_product_category_3'),
          this.get_common_column('home_product_category_4'),
        ];
      }
    },
  },
};
</script>
