<template>
  <section
    ref="MainContent"
    id="HomeMainProduct"
    class="w-full overflow-hidden main_product_01"
  >
    <div
      v-if="main_products != null"
      class="relative flex flex-col-reverse items-center justify-center w-full max-w-screen-xl py-4 mx-auto lg:py-20 sm:py-10 md:flex-row-reverse"
    >
      <div
        class="relative z-10 w-full px-4 pt-10 text-white md:w-1/2 sm:px-10 md:bg-transparent md:pt-0"
      >
        <p class="mb-2 text-4xl font-bold opacity-0 text-primary sub_title">
          {{ main_products.sub_title }}
        </p>
        <div class="relative inline-block mb-5 overflow-hidden title_box">
          <span
            class="absolute top-0 bottom-0 left-0 right-0 transform -translate-x-full bg-primary"
          ></span>
          <h3 class="text-5xl font-bold opacity-0">
            {{ main_products.title }}
          </h3>
        </div>
        <div
          class="mb-10 font-medium text-white opacity-0 text-opacity-70 content"
          v-html="main_products.content"
        ></div>
        <a
          :href="main_products.link"
          class="inline-block px-8 py-2 text-base font-bold text-white transition-colors duration-500 bg-opacity-50 border border-black opacity-0 bg-primary md:hover:bg-opacity-100 more_btn"
          >查看詳細資料</a
        >
      </div>
      <div class="relative z-10 w-full px-4 mb-10 md:w-1/2 lg:mb-0 sm:px-10">
        <div class="md:w-full w-[85%] mx-auto overflow-hidden">
          <img
            width="600"
            height="400"
            :alt="main_products.title"
            :src="main_products.image"
            class="object-cover w-full h-full opacity-0 gsap_item"
          />
        </div>
      </div>
      <div
        class="bg_box absolute md:top-1/2 top-0 right-0 left-0 md:opacity-100 opacity-50 flex justify-start transform md:-translate-y-1/2 -translate-x-[8%] md:h-2/3 h-2/3"
      >
        <div
          class="flex-shrink-0 w-[350px] h-full transform -skew-x-[20deg] overflow-hidden relative mr-20"
        >
          <div
            class="absolute top-0 bottom-0 left-0 right-0 transform -translate-x-full bg-gradient-to-b from-primary to-transparent gsap_item"
          ></div>
        </div>
        <div
          class="flex-shrink-0 w-44 h-full transform -skew-x-[20deg] overflow-hidden relative"
        >
          <div
            class="absolute top-0 bottom-0 left-0 right-0 transform -translate-x-full opacity-30 bg-gradient-to-b from-gray-400 to-transparent gsap_item"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { main_product_01 } from '@/gsap/home/main_product_01';
export default {
  name: 'HomeAbout',
  data() {
    return {
      main_product_01: null,
    };
  },
  methods: {
    SetGsap() {
      this.main_product_01 = new main_product_01(this.$refs.MainContent);
    },
  },
  computed: {
    main_products() {
      return this.$store.getters.get_common_column('home_main_product_1');
    },
  },
};
</script>
