/* eslint-disable no-unused-vars */
import gsap from 'gsap';

gsap.config({
  autoSleep: 60,
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
  units: {
    left: '%',
    top: '%',
    rotation: 'rad',
  },
});

export class home_news {
  constructor(el) {
    this.el = el;
    this.active_index = 0;
    this.cards = this.el.querySelectorAll('[data-news-item]');

    this.timeline = null;
  }
  NextList() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();

    if (this.active_index == this.cards.length - 1) {
      this.active_index = 0;
    } else {
      this.active_index += 1;
    }

    this.timeline.to(this.cards, {
      x: `${this.active_index * -100}%`,
    });
  }

  PrevList() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();

    if (this.active_index == 0) {
      this.active_index = this.cards.length - 1;
    } else {
      this.active_index -= 1;
    }

    this.timeline.to(this.cards, {
      x: `${this.active_index * -100}%`,
    });
  }

  destroy() {
    this.timer != null ? clearTimeout(this.timer) : '';
    this.timeline != null ? this.timeline.kill() : '';
  }
}
