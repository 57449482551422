<template>
  <header class="fixed top-0 left-0 z-30 w-screen">
    <div
      :class="menu_bg ? 'md:bg-black md:bg-opacity-50' : ''"
      class="flex items-center justify-between w-full max-w-full px-4 mx-auto transition-all duration-200 xl:px-8 md:px-6"
    >
      <div
        :class="menu_bg ? 'lg:py-6 py-4 ' : 'lg:py-10 md:py-6 py-4'"
        class="header relative z-[41] lg:w-auto w-full flex items-center justify-between transition-all duration-200"
      >
        <router-link to="/"
          ><img
            width="128"
            height="28"
            :class="menu_bg ? 'xl:w-32 w-32' : 'xl:w-44 w-32'"
            class=""
            alt="APEXX"
            src="/img/logo.webp"
        /></router-link>

        <div class="flex">
          <router-link
            to="/cart"
            class="relative block mr-3 overflow-hidden transition-all transform lg:hidden md:hover:-rotate-45"
          >
            <div
              class="flex items-center justify-center w-10 h-10 duration-300 border border-black rounded-full bg-primary"
            >
              <span class="text-lg text-black icon-shopping_cart"> </span>
            </div>
          </router-link>
          <button
            title="打開目錄"
            class="relative block overflow-hidden transition-all lg:hidden"
            @click="SetMenuActive(!menu_active)"
          >
            <div
              class="absolute flex items-center justify-center w-10 h-10 transition-opacity duration-300 transform bg-white rounded-full"
              :class="
                menu_active
                  ? 'opactiy-100 translate-x-0'
                  : 'opactiy-0 translate-x-full'
              "
            >
              <span class="text-gray-800 icon-close"> </span>
            </div>

            <div
              class="flex items-center justify-center w-10 h-10 transition-colors duration-300 bg-gray-900 border rounded-full border-primary hover:bg-primary"
            >
              <span class="text-white icon-menu"> </span>
            </div>
          </button>
        </div>
      </div>

      <!-- MENU -->
      <div
        class="fixed top-0 bottom-0 left-0 z-40 flex items-center w-full transition-opacity duration-300 transform bg-black lg:relative lg:w-auto lg:bottom-auto lg:z-30 lg:bg-transparent bg-opacity-90"
        :class="
          menu_active
            ? 'opacity-100 translate-x-0'
            : 'lg:opacity-100 opacity-0 lg:translate-x-0 -translate-x-full'
        "
      >
        <ol
          class="flex flex-col items-center w-4/5 mx-auto lg:flex-row lg:w-auto"
        >
          <li v-for="item in menu_list" class="w-full lg:w-auto" :key="item.id">
            <router-link
              v-if="item.title != '商品列表'"
              class="relative flex flex-col w-full px-6 py-2 overflow-hidden text-xl font-bold text-white duration-300 border-b border-gray-800 lg:text-primary lg:text-center lg:flex-col-reverse lg:py-1 lg:tracking-widest lg:border-b-0 md:hover:text-primary lg:text-sm lg:h-auto lg:w-auto transition-color"
              @click.native="SetMenuActive(false)"
              :to="item.url"
            >
              {{ item.title }}
              <span
                class="block text-base lg:text-white text-primary lg:text-lg lg:font-bold"
                >{{ item.sub_title }}</span
              >
            </router-link>
            <div
              @mouseenter="hover_drop_down = true"
              @mouseleave="hover_drop_down = false"
              v-else
              class="relative"
            >
              <router-link
                to="/products"
                class="relative flex flex-col w-full px-6 py-2 overflow-hidden text-xl font-bold text-white duration-300 border-b border-gray-800 lg:text-primary lg:text-center lg:flex-col-reverse lg:py-1 lg:tracking-widest lg:border-b-0 md:hover:text-primary lg:text-sm lg:h-auto lg:w-auto transition-color"
                @click.native="SetMenuActive(false)"
              >
                {{ item.title }}
                <span
                  class="block text-base lg:text-white text-primary lg:text-lg lg:font-bold"
                  >{{ item.sub_title }}</span
                >
              </router-link>
              <ol
                class="w-full py-2 text-white bg-black border-b border-gray-800 lg:border-none bg-opacity-40 lg:w-auto lg:bg-opacity-100 lg:absolute lg:left-0 lg:top-full"
                :class="hover_drop_down ? 'lg:block ' : 'lg:hidden '"
              >
                <li
                  v-for="(category, category_index) in product_category"
                  :key="`category_${category_index}`"
                >
                  <router-link
                    @click.native="SetMenuActive(false)"
                    :to="`/products?category=${category.category_id}`"
                    class="block px-6 py-1 font-bold text-left duration-300 lg:py-2 md:hover:text-primary transition-color"
                  >
                    {{ category.title }}
                  </router-link>
                </li>
              </ol>
            </div>
          </li>
          <li class="pl-2 border-l lg:inline-block border-primary">
            <router-link
              to="/cart"
              class="relative flex items-center px-4 py-2"
            >
              <span class="icon-shopping_cart text-primary"> </span>
              <span
                class="flex items-center justify-center ml-2 text-base font-bold transform rounded-full text-primary"
                >{{ shop_cart }}</span
              >
            </router-link>
          </li>
        </ol>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'MainNav',
  data() {
    return {
      menu_bg: false,
      menu_list: [
        {
          title: '關於我們',
          sub_title: 'ABOUT',
          url: '/about',
        },
        {
          title: '商品列表',
          sub_title: 'PRODUCTS',
          url: '/products',
        },
        {
          title: '影音相簿',
          sub_title: 'VIDEOS',
          url: '/video/list',
        },
        {
          title: '最新消息',
          sub_title: 'NEWS',
          url: '/news/list',
        },
        {
          title: '客服中心',
          sub_title: 'SUPPORT',
          url: '/support',
        },
        {
          title: '經銷據點',
          sub_title: 'DEALERS',
          url: '/dealers',
        },
      ],
      hover_drop_down: false,
    };
  },
  computed: {
    menu_active() {
      return this.$store.state.main_menu;
    },
    product_category() {
      if (this.$store.state.product_category_data == null) {
        return [];
      } else {
        return this.$store.state.product_category_data.filter(
          (item) => item.parent != '0'
        );
      }
    },
    shop_cart() {
      let amount = 0;
      this.$store.state.shop_cart.forEach((item) => {
        amount += parseInt(item.amount);
      });
      return amount;
    },
  },
  methods: {
    SetMenuActive(action) {
      this.$store.commit('SetMainMenu', action);
    },
    CheckScroll() {
      window.scrollY >= 200 || this.$route.name == 'dealers'
        ? (this.menu_bg = true)
        : (this.menu_bg = false);
    },
  },
  watch: {
    $route() {
      this.CheckScroll();
    },
  },
  mounted() {
    this.CheckScroll();
    window.addEventListener('scroll', () => {
      this.CheckScroll();
    });
  },
};
</script>
