<template>
  <div ref="MainContent" class="w-full overflow-hidden home_carousel">
    <div class="w-full md:aspect-w-5 aspect-w-1 aspect-h-1 md:aspect-h-2">
      <div>
        <div
          class="absolute z-10 transform -translate-y-1/2 gsap_item step_line_box top-1/2 lg:right-10 md:right-8 right-4 h-2/5"
        >
          <ol class="flex flex-col justify-between h-full">
            <li
              v-for="(item, item_index) in carousel_data"
              :key="item.id"
              class="relative z-[11]"
            >
              <span class="block w-2 h-2 bg-white rounded-full"></span>
              <span
                :class="item_index == active_carousel ? 'scale-100' : 'scale-0'"
                class="absolute transition-all duration-500 transform top-1/2 left-1/2"
              >
                <span
                  :class="{
                    'animate-[rotate_linear_3s_infinite]':
                      item_index == active_carousel,
                  }"
                  class="absolute block w-4 h-4 transform -translate-x-1/2 -translate-y-1/2 border border-white border-dashed rounded-full md:w-6 md:h-6 top-1/2 left-1/2"
                ></span>
              </span>
            </li>
          </ol>
          <span
            class="line absolute top-0 bottom-0 left-1/2 transform -translate-x-1/2 w-[1px] bg-white bg-opacity-50"
          ></span>
        </div>

        <span
          class="time_line absolute left-0 top-0 bottom-0 w-[2px] bg-primary z-10"
        ></span>

        <p
          class="absolute z-10 text-sm tracking-widest text-white lg:right-10 md:right-8 right-4 md:bottom-10 bottom-5 md:text-base"
        >
          {{ active_carousel + 1 }} / {{ carousel_data.length }}
        </p>
        <div class="relative w-full h-full carousel_box gsap_item">
          <div
            class="relative w-full h-full carousel_item gsap_item"
            v-for="item in carousel_data"
            :key="item.id"
          >
            <div
              class="text_box absolute z-[3] md:left-10 left-2 md:bottom-10 bottom-5 flex"
            >
              <span
                class="text_line xl:w-14 scale-x-0 w-7 h-[1px] bg-white xl:mr-10 mr-2 xl:mt-3 mt-2 block"
              ></span>
              <div>
                <p
                  class="sub_title tracking-wider text-primary font-bold xl:mb-2 md:mb-2 opacity-0 xl:text-base md:text-sm text-[8px]"
                  v-html="item.sub_title"
                ></p>
                <div
                  class="relative mb-2 overflow-hidden title_box xl:py-1 xl:mb-5"
                >
                  <span
                    class="absolute top-0 bottom-0 left-0 right-0 transform -translate-x-full bg-primary"
                  ></span>
                  <h3
                    class="text-2xl font-bold text-white opacity-0 title xl:text-5xl md:text-4xl"
                    v-html="item.title"
                  ></h3>
                </div>
                <!-- <p class="text-white content" v-html="item.content"></p> -->
                <a
                  :href="item.link"
                  class="inline-block px-8 py-2 text-base font-bold text-white transition-colors duration-500 bg-opacity-50 border border-black opacity-0 bg-primary md:hover:bg-opacity-100"
                >
                  查看詳細資料
                </a>
              </div>
            </div>
            <div
              class="dark_cover bg-black bg-opacity-60 opacity-0 absolute w-full h-full left-0 top-0 z-[2]"
            ></div>
            <div class="pb-0 aspect-h-1 aspect-w-1 md:aspect-h-2 md:aspect-w-5">
              <img
                width="1920"
                height="1080"
                :src="item.image_pc"
                :alt="item.title"
                class="w-full h-full md:block hidden object-cover object-center relative z-[1]"
              />
              <img
                width="768"
                height="768"
                :src="item.image_mb"
                :alt="item.title"
                class="w-full h-full md:hidden block object-cover object-center relative z-[1]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { home_carousel } from '@/gsap/home/carousel';
export default {
  name: 'HomeCarousel',
  data() {
    return {
      home_carousel_animation: null,
    };
  },
  methods: {
    SetGsap() {
      this.home_carousel_animation = new home_carousel(this.$refs.MainContent);
    },
  },
  computed: {
    carousel_data() {
      let tmp_data = this.$store.state.carousel_data;
      return tmp_data == null ? [] : tmp_data;
    },
    active_carousel() {
      if (this.home_carousel_animation == null) {
        return -1;
      } else {
        return this.home_carousel_animation.active_carousel;
      }
    },
  },
  beforeDestroy() {
    this.home_carousel_animation != null
      ? this.home_carousel_animation.destroy()
      : '';
    this.home_carousel_animation = null;
  },
};
</script>
