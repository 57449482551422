import router from "@/router/index";
import store from "@/store/index";
import { post_form } from "@/common/request";
var test_code = "";

// Meta Pixel

export function ViewContent(product) {
  const event_id = getEventID(10);
  const price = GetLowPrice(product);
  window.fbq(
    "track",
    "ViewContent",
    {
      content_ids: ["apexx_" + product.product_id],
      content_category: "瀏覽商品-" + product.title,
      content_name: "瀏覽商品-" + product.title,
      content_type: "product",
      contents: [
        {
          id: "apexx_" + product.product_id,
          quantity: 1,
          price: price,
        },
      ],
      currency: "TWD",
      value: 0,
    },
    { eventID: event_id }
  );
  APIViewContent(product, price, event_id);
}

export function PixelAddToCart(product, option, amount) {
  product;
  const price = GetSellPrice(product, option);
  const option_text = GetOptionText(product, option);
  const event_id = getEventID(10);

  window.fbq(
    "track",
    "AddToCart",
    {
      content_ids: ["apexx_" + product.product_id],
      content_name: product.title,
      content_type: "product",
      contents: [
        {
          id: "apexx_" + product.product_id,
          quantity: amount,
          price: parseInt(price),
          option: option_text,
        },
      ],
      currency: "TWD",
      value: 0,
    },
    { eventID: event_id }
  );

  APIAddToCart(product, amount, price, option_text, event_id);
}

export function InitiateCheckout(shopcart, total_price) {
  let { product_content, product_ids, total_amount } =
    GetShopCartContent(shopcart);
  const event_id = getEventID(10);

  window.fbq(
    "track",
    "InitiateCheckout",
    {
      content_category: "結帳頁面",
      content_ids: product_ids,
      contents: product_content,
      num_items: total_amount,
      currency: "TWD",
      value: 0,
    },
    { eventID: event_id }
  );

  APIInitCheckout(
    product_content,
    product_ids,
    total_amount,
    total_price,
    event_id
  );
}

export function Purchase(shopcart, trade_id, total_price, form_data) {
  let { product_content, product_ids, total_amount } =
    GetShopCartContent(shopcart);

  window.fbq(
    "track",
    "Purchase",
    {
      content_ids: product_ids,
      content_name: "建立訂單",
      content_type: "product",
      contents: product_content,
      currency: "TWD",
      num_items: total_amount,
      value: parseInt(total_price),
    },
    { eventID: "purchase." + trade_id }
  );

  APIPurchase(
    product_content,
    product_ids,
    total_amount,
    trade_id,
    total_price,
    form_data,
    "purchase." + trade_id
  );
}

export function Search(products, search_string) {
  let product_ids = [];
  let product_content = [];
  const event_id = getEventID(10);
  products.forEach((item) => {
    product_ids.push("apexx_" + item.product_id);
    product_content.push({
      id: "apexx_" + item.product_id,
      quantity: item.amount,
      price: GetLowPrice(item),
    });
  });

  window.fbq(
    "track",
    "Search",
    {
      content_category: "搜尋商品",
      content_ids: product_ids,
      content_name: "建立訂單",
      content_type: "product",
      contents: product_content,
      currency: "TWD",
      search_string: search_string,
      value: 0,
    },
    { eventID: event_id }
  );

  APISearch(search_string, product_ids, product_content, event_id);
}

export function Contact() {
  const event_id = getEventID(10);
  window.fbq(
    "track",
    "Contact",
    {
      content_name: "聯絡我們",
      status: true,
      currency: "TWD",
      value: 0,
    },
    { eventID: event_id }
  );

  APIContact(event_id);
}

// ConvertAPI

function SendAPI(path, form) {
  if (getCookie("_fbp")) {
    form.FBP = getCookie("_fbp");
  }
  if (getCookie("_fbc")) {
    form.FBP = getCookie("_fbc");
  }
  if (test_code != "") {
    form.TestCode = test_code;
  }

  form.FacebookPageID = 1698834777250433;

  let pixel_data = new FormData();
  pixel_data.append("pixel_data", JSON.stringify(form));

  return post_form(path, pixel_data, false);
}

// 瀏覽頁面
export function APIViewPage() {
  const event_id = getEventID(10);
  window.fbq("track", "PageView", {}, { eventID: event_id });
  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    EventID: event_id,
  };
  return SendAPI("/facebook/view", form);
}
// 瀏覽商品
export function APIViewContent(product, price, event_id) {
  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    EventID: event_id,
    Product: product,
    Price: price,
  };
  return SendAPI("/facebook/viewContent", form);
}
// 查看經銷據點
export function APIViewDealer() {
  const event_id = getEventID(10);
  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    EventID: event_id,
  };
  return SendAPI("/facebook/dealer", form);
}
// 加入購物車
export function APIAddToCart(product, amount, price, option_text, event_id) {
  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    Product: product,
    Price: price,
    ProductOptions: {
      id: "apexx_" + product.product_id,
      quantity: amount,
      item_price: price,
    },
    EventID: event_id,
  };

  return SendAPI("/facebook/addCart", form);
}
// 開始結帳
export function APIInitCheckout(
  product_content,
  product_ids,
  total_amount,
  total_price,
  event_id
) {
  let tmp_content = JSON.parse(JSON.stringify(product_content));
  tmp_content.forEach((item, item_index) => {
    tmp_content[item_index].item_price = item.price;
    delete tmp_content[item_index].price;
    delete tmp_content[item_index].option;
  });

  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    ProductContent: tmp_content,
    ProductIDList: product_ids,
    Amount: total_amount,
    Price: total_price,
    EventID: event_id,
  };
  return SendAPI("facebook/initCheckout", form);
}
// 送出訂單
export function APIPurchase(
  product_content,
  product_ids,
  total_amount,
  trade_id,
  total_price,
  form_data,
  event_id
) {
  let tmp_content = JSON.parse(JSON.stringify(product_content));
  tmp_content.forEach((item, item_index) => {
    tmp_content[item_index].item_price = item.price;
    delete tmp_content[item_index].price;
    delete tmp_content[item_index].option;
  });

  // form_data.zip_code = "";

  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    Payment: "",
    UserData: form_data,
    Price: total_price,
    TradeID: trade_id,
    ProductIDList: product_ids,
    ProductContent: tmp_content,
    Amount: total_amount,
    EventID: event_id,
  };
  return SendAPI("facebook/purchase", form);
}
// 搜尋商品
export function APISearch(
  search_string,
  product_ids,
  product_content,
  event_id
) {
  let tmp_content = JSON.parse(JSON.stringify(product_content));
  tmp_content.forEach((item, item_index) => {
    tmp_content[item_index].item_price = item.price;
    delete tmp_content[item_index].price;
  });
  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    KeyWord: search_string,
    ProductIDList: product_ids,
    ProductContent: tmp_content,
    EventID: event_id,
  };
  return SendAPI("/facebook/search", form);
}
// 註冊會員
export function APICompleteRegistration(event_id) {
  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    EventID: event_id,
  };
  return SendAPI("/facebook/registration", form);
}
// 增加付款資訊
export function APIAddPayment(
  product_content,
  product_ids,
  total_amount,
  total_price,
  form_data,
  event_id
) {
  let tmp_content = JSON.parse(JSON.stringify(product_content));
  tmp_content.forEach((item, item_index) => {
    tmp_content[item_index].item_price = item.price;
    delete tmp_content[item_index].price;
    delete tmp_content[item_index].option;
  });

  const zip_code = store.state.zipcode_data.filter(
    (item) =>
      item.Area == form_data.consignee_area &&
      item.City == form_data.consignee_city
  )[0].ZipCode;

  form_data.zip_code = zip_code;
  form_data.payment = store.state.payment_data.filter(
    (item) => item.PaymentID == form_data.pay_way
  )[0].title;

  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    ProductContent: tmp_content,
    ProductIDList: product_ids,
    Amount: total_amount,
    Price: total_price,
    UserData: form_data,
    EventID: event_id,
  };

  return SendAPI("/facebook/add_payment", form);
}
// 聯絡我們
export function APIContact(event_id) {
  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    EventID: event_id,
  };
  return SendAPI("/facebook/contact", form);
}

// 資料轉換

function GetShopCartContent(shopcart) {
  let product_ids = [];
  let product_content = [];
  let total_amount = 0;
  shopcart.forEach((item) => {
    const price = parseInt(item.variant.compare_at_price) * item.amount;
    let option_text = "";

    item.product.options.forEach((option, option_index) => {
      option_text != "" ? (option_text += " / ") : "";
      option_text +=
        option.title + "：" + item.variant[`option${option_index + 1}`];
    });

    product_ids.push("apexx_" + item.product.product_id);
    product_content.push({
      id: "apexx_" + item.product.product_id,
      quantity: item.amount,
      price: parseInt(price),
      option: option_text,
    });

    total_amount += parseInt(item.amount);
  });

  return {
    total_amount: total_amount,
    product_ids: product_ids,
    product_content: product_content,
  };
}

function GetOptionText(product_data, options) {
  let text = "";
  product_data.options.forEach((item, item_index) => {
    text != "" ? text + " / " : "";
    text += item.title + "：" + options[`option${item_index + 1}`];
  });

  return text;
}

function GetSellPrice(product_data, options) {
  let price = 0;
  product_data.variants.forEach((item) => {
    item.option1 == options.option1 &&
    item.option2 == options.option2 &&
    item.option3 == options.option3
      ? (price = item.compare_at_price)
      : "";
  });
  return parseInt(price);
}

function GetLowPrice(product_data) {
  let price = 999999999;
  product_data.variants.forEach((variant) => {
    variant.compare_at_price < price ? (price = variant.compare_at_price) : "";
  });
  return parseInt(price).toFixed(0);
}

function getCookie(name) {
  function escape(s) {
    // eslint-disable-next-line no-useless-escape
    return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, "\\$1");
  }
  var match = document.cookie.match(
    RegExp("(?:^|;\\s*)" + escape(name) + "=([^;]*)")
  );
  return match ? match[1] : null;
}

function getEventID(randomLength) {
  return Number(
    Math.random().toString().substr(2, randomLength) + Date.now()
  ).toString(36);
}
