<template>
  <div class="absolute top-0 bottom-0 left-0 right-0 bg-zinc-800">
    <div
      class="w-full h-full opacity-[.03] relative z-10"
      style="background-image: url('/img/dot_bg.png'); background-size: 20px"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'MainBg',
};
</script>
