<template>
  <section
    id="HomeAbout"
    v-if="about_data != null"
    class="w-full mb-20 overflow-hidden"
  >
    <div
      class="relative flex flex-col-reverse items-center justify-center w-full max-w-screen-xl py-4 mx-auto lg:py-20 sm:py-10 md:flex-row"
    >
      <div
        class="relative z-10 w-full px-4 text-white md:w-1/2 sm:pl-0 sm:pr-10"
      >
        <p class="text-4xl font-bold opacity-0 text-primary sub_title">
          {{ about_data.sub_title }}
        </p>
        <div class="relative inline-block mb-5 overflow-hidden title_box">
          <span
            class="absolute top-0 bottom-0 left-0 right-0 transform -translate-x-full bg-primary"
          ></span>
          <h3 class="text-5xl font-bold opacity-0">{{ about_data.title }}</h3>
        </div>
        <div
          class="mb-10 font-medium text-white opacity-0 content text-opacity-70"
          v-html="about_data.content"
        ></div>
        <router-link
          :to="about_data.link"
          class="inline-block px-8 py-2 text-base font-bold text-white transition-colors duration-500 bg-opacity-50 border border-black opacity-0 bg-primary md:hover:bg-opacity-100 more_btn"
          >查看品牌介紹</router-link
        >
      </div>
      <div class="relative z-10 w-full px-4 mb-10 md:w-1/2 lg:mb-0 sm:px-10">
        <div class="w-full overflow-hidden aspect-h-9 aspect-w-16">
          <img
            width="560"
            height="315"
            :alt="about_data.title"
            :src="about_data.image"
            class="object-cover w-full h-full opacity-0 gsap_item"
          />
        </div>
      </div>
      <div
        class="absolute top-0 bottom-0 left-0 right-0 flex justify-end opacity-50 bg_box md:opacity-100"
      >
        <div
          class="flex-shrink-0 w-44 mr-0 h-3/4 transform -skew-x-[20deg] translate-x-1/4 overflow-hidden relative"
        >
          <div
            class="absolute top-0 bottom-0 left-0 right-0 transform translate-x-full bg-gray-400 bg-opacity-10 gsap_item"
          ></div>
        </div>
        <div
          class="flex-shrink-0 w-[350px] h-full transform -skew-x-[20deg] translate-x-1/4 overflow-hidden relative"
        >
          <div
            class="absolute top-0 bottom-0 left-0 right-0 transform translate-x-full bg-opacity-30 bg-primary gsap_item"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import GSAP from '@/gsap/home/about';
export default {
  name: 'HomeAbout',
  mixins: [GSAP],
  computed: {
    about_data() {
      return this.$store.getters.get_common_column('home_about');
    },
  },
};
</script>
