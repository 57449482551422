<template>
  <div
    id="ShopCartDialog"
    class="fixed top-0 left-0 right-[120%] bottom-0 bg-black bg-opacity-50 z-40 flex items-center justify-center overflow-hidden"
  >
    <div class="text_box w-5/6 sm:w-96 py-16 bg-primary relative z-[41]">
      <h4 class="text-xl font-bold text-center text-white">
        您的商品已加入購物車
      </h4>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
export default {
  name: 'ShopCartDialog',
  data() {
    return {};
  },
  methods: {
    Show() {
      let vm = this;
      let tl = gsap.timeline();
      let main_el = document.querySelector('#ShopCartDialog');
      let text_box = main_el.querySelector('.text_box');

      tl.set(main_el, {
        right: 0,
      });
      tl.fromTo(
        main_el,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.4,
        }
      );
      tl.fromTo(
        text_box,
        {
          scale: 0,
          transformOrigin: 'center',
        },
        {
          scale: 1,
          transformOrigin: 'center',
          duration: 0.3,
          onComplete: () => {
            setTimeout(() => {
              vm.$store.commit('SetShopCartDialog', false);
            }, 800);
          },
        }
      );
    },
    Hide() {
      let tl = gsap.timeline();
      let main_el = document.querySelector('#ShopCartDialog');
      let text_box = main_el.querySelector('.text_box');

      tl.fromTo(
        text_box,
        {
          scale: 1,
          transformOrigin: 'center',
        },
        {
          scale: 0,
          transformOrigin: 'center',
          duration: 0.3,
        }
      );

      tl.fromTo(
        main_el,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 0.4,
        }
      );

      tl.set(main_el, {
        right: '100%',
      });
    },
  },
  watch: {
    dialog() {
      this.dialog ? this.Show() : this.Hide();
    },
  },
  computed: {
    dialog() {
      return this.$store.state.shop_cart_dialog;
    },
  },
};
</script>
