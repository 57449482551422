import router from '@/router/index';

var default_meta = {
  title: '官方網站',
  content: 'Apexx 專營改裝品開發',
  image: 'https://www.apexxmoto.com/img/share/index.jpg',
};

export function GetMetaData(title, content, image) {
  title = title == '' ? default_meta.title : title;
  return {
    title: title + ' | APEXX RACING',
    meta: [
      {
        property: 'og:description',
        name: 'og:description',
        content: content == '' ? default_meta.content : content,
        vmid: 'og:description',
      },
      {
        property: 'twitter:description',
        name: 'twitter:description',
        content: content == '' ? default_meta.content : content,
        vmid: 'twitter:description',
      },
      {
        property: 'description',
        name: 'description',
        content: content == '' ? default_meta.content : content,
        vmid: 'description',
      },
      {
        property: 'og:image',
        name: 'og:image',
        content: image == '' ? default_meta.image : image,
        vmid: 'og:image',
      },
      {
        property: 'apple-mobile-web-app-title',
        name: 'apple-mobile-web-app-title',
        content: title + ' | APEXX RACING',
        vmid: 'apple-mobile-web-app-title',
      },
      {
        property: 'application-name',
        name: 'application-name',
        content: title + ' | APEXX RACING',
        vmid: 'application-name',
      },
      {
        property: 'og:site_name',
        name: 'og:site_name',
        content: '官方網站 | APEXX RACING',
        vmid: 'og:site_name',
      },
      {
        property: 'og:type',
        name: 'og:type',
        content: 'website',
        vmid: 'og:type',
      },
      {
        property: 'og:locale',
        name: 'og:locale',
        content: 'zh_tw',
        vmid: 'og:locale',
      },
      {
        property: 'og:url',
        name: 'og:url',
        content: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
        vmid: 'og:url',
      },
    ],
  };
}
