<template>
  <section id="MainContact" v-if="contact_data != null">
    <div class="relative z-10 w-full py-20 bg-center bg-cover">
      <div class="relative z-10 w-full max-w-screen-xl px-20 mx-auto xl:px-0">
        <h4 class="mb-2 text-4xl font-bold text-primary">CONTACT</h4>
        <h3
          class="mb-3 text-2xl font-bold leading-snug tracking-wider text-white"
        >
          {{ contact_data.title }}
        </h3>
        <div
          class="mb-8 text-sm text-gray-400"
          v-html="contact_data.content"
        ></div>

        <a
          class="inline-block px-8 py-2 text-base font-bold text-white transition-colors duration-500 bg-opacity-50 border border-black bg-primary md:hover:bg-opacity-100"
          :href="contact_data.link"
        >
          CONTACT
        </a>
      </div>
      <div
        class="absolute top-0 left-0 right-0 bottom-0 z-[5] bg-black opacity-60"
      ></div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MainContact',
  computed: {
    contact_data() {
      return this.$store.getters.get_common_column('home_contact');
    },
  },
};
</script>
