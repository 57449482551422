/* eslint-disable no-unused-vars */
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.config({
  autoSleep: 60,
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
  units: {
    left: '%',
    top: '%',
    rotation: 'rad',
  },
});

export class main_product_01 {
  constructor(el) {
    this.el = el;
    this.bg_el = el.querySelectorAll('.bg_box div div');
    this.image = el.querySelector('img');
    this.title_cover = el.querySelector('.title_box span');
    this.title = el.querySelector('.title_box h3');
    this.sub_title = el.querySelector(' .sub_title');
    this.content = el.querySelector(' .content');
    this.more_btn = el.querySelector(' .more_btn');

    this.timeline = null;
    this.setup();
  }

  setup() {
    this.timeline != null ? this.timeline.kill() : '';
    let timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.el,
        start: 'top 70%',
        toggleActions: 'restart play play reverse',
      },
    });
    timeline.fromTo(
      this.bg_el[0],
      {
        x: '-105%',
      },
      {
        x: '0%',
        ease: 'easeInOut',
        duration: 0.4,
      },
      0
    );
    timeline.fromTo(
      this.bg_el[1],
      {
        x: '-105%',
      },
      {
        x: '0%',
        ease: 'easeInOut',
        delay: 0.4,
        duration: 0.3,
      },
      0
    );
    timeline.fromTo(
      this.image,
      {
        // webkitFilter: "blur(500px)",
        // rotate: '180deg',
        x: '-105%',
        transformOrigin: 'center',
        opacity: 0,
      },
      {
        // rotate: '0deg',
        x: '0%',
        transformOrigin: 'center',
        opacity: 1,
        delay: 0.4,
        duration: 0.5,
      },
      0
    );

    timeline.fromTo(
      this.sub_title,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 0.3,
        duration: 0.3,
      },
      0
    );
    timeline.fromTo(
      this.title_cover,
      {
        x: '-105%',
      },
      {
        x: '-0%',
        delay: 0.4,
        duration: 0.3,
      },
      0
    );
    timeline.set(
      this.title,
      {
        opacity: 1,
        delay: 0.7,
      },
      0
    );
    timeline.to(
      this.title_cover,
      {
        x: '110%',
        duration: 0.2,
      },
      1
    );
    timeline.fromTo(
      this.content,
      {
        y: '30px',
        opacity: 0,
      },
      {
        y: '0px',
        opacity: 1,
        duration: 0.6,
      },
      1
    );
    timeline.fromTo(
      this.more_btn,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.6,
        delay: 0.4,
      },
      1
    );
  }
}
