// import base_meta from '@/assets/base_meta.json';
import Vue from 'vue';
import store from '@/store';
import router from '@/router';

Vue.prototype.$ImageUrl = (item) => {
  // return item;
  return item == '' ? '' : 'https://www.apexxmoto.com/php/' + item;
};

Vue.prototype.$GetColumn = (key) => {
  if (store.state.common_column_data == null) {
    return '';
  }
  const column_data = store.state.common_column_data.filter(
    (column) => column.column_name == key
  );
  return column_data.length > 0 ? column_data[0] : '';
};

Vue.prototype.$MoneyFormat = (price) => {
  let val = (price / 1).toFixed(0).replace('.', ',');
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

Vue.prototype.$PageReady = (title) => {
  CheckFontLoad();

  window.prerenderReady = true;
  window.dataLayer.push({
    event: 'page_view',
    page_title: title,
  });
};

Vue.prototype.$RedirectError = () => {
  var meta = document.createElement('meta');
  meta.setAttribute('property', 'prerender-status-code');
  meta.setAttribute('name', 'prerender-status-code');
  meta.content = '404';
  document.getElementsByTagName('head')[0].appendChild(meta);
  router.push('/error_page');
};

Vue.prototype.$LoadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.setAttribute('src', src);
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

Vue.prototype.$AddZeroPad = (val) => {
  return parseInt(val) < 10 ? '0' + val : val;
};

// Vue.prototype.$GetMetaData = (title, description, image) => {
//   let meta_data = Object.assign({}, base_meta);
//   if (title != '') {
//     meta_data.title = title + '｜' + meta_data.title;
//     meta_data.meta[1].content = title + '｜' + meta_data.meta[1].content;
//   }
//   if (description != '') {
//     meta_data.meta[2].content = description
//       .replace(/(<([^>]+)>)/gi, '')
//       .slice(0, 160);
//     meta_data.meta[3].content = description
//       .replace(/(<([^>]+)>)/gi, '')
//       .slice(0, 160);
//     meta_data.meta[4].content = description
//       .replace(/(<([^>]+)>)/gi, '')
//       .slice(0, 160);
//   }
//   if (image != '') {
//     meta_data.meta[5].content = process.env.VUE_APP_BASEURL + image;
//   }
//   meta_data.meta[0].content =
//     process.env.VUE_APP_BASEURL + router.history.current.fullPath;

//   return meta_data;
// };

Vue.prototype.$LoadAllFont = () => {
  LoadAllFont();
};

function LoadAllFont() {
  if (
    document.head.querySelectorAll(
      '[href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"]'
    ).length <= 0
  ) {
    let css_link = document.createElement('link');
    css_link.href =
      'https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap';
    css_link.rel = 'stylesheet';
    css_link.type = 'text/css';
    document.head.appendChild(css_link);
  }
}

function LoadFont(text) {
  if (
    document.head.querySelectorAll(
      '[href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"]'
    ).length <= 0
  ) {
    let css_link = document.createElement('link');
    css_link.href =
      'https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap&text=' +
      encodeURIComponent(text);
    css_link.rel = 'stylesheet';
    css_link.type = 'text/css';
    document.head.appendChild(css_link);

    let css_link_2 = document.createElement('link');
    css_link_2.href =
      'https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap&text=' +
      encodeURIComponent(text);
    css_link_2.rel = 'stylesheet';
    css_link_2.type = 'text/css';
    document.head.appendChild(css_link_2);
  }
}

Vue.prototype.$GetBodyText = () => {
  return GetBodyText();
};

function GetBodyText() {
  let str = document.body.innerText;
  let uniqueChars = '';
  for (let i = 0; i < str.length; i++) {
    if (str.charAt(i) !== ' ' && uniqueChars.indexOf(str.charAt(i)) === -1) {
      uniqueChars += str[i];
    }
  }
  uniqueChars = uniqueChars.replace(/\s/g, '');
  str = [...new Set(uniqueChars)].join('');

  str = str.replace(
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
    ''
  );
  return str;
}

function CheckFontLoad() {
  const links = document.head.querySelectorAll('link');
  let font_loaded = false;
  links.forEach((link) => {
    link.href.indexOf('css2?family=') != -1 ? (font_loaded = true) : '';
  });
  if (font_loaded) {
    LoadAllFont();
  } else {
    const body_text = GetBodyText();
    body_text != '' ? LoadFont(body_text) : '';
  }
}
