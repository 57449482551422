<template>
  <footer
    class="relative z-10 w-screen bg-black"
    style="background-image: url('/img/dot_cover.png')"
  >
    <div class="flex flex-wrap w-full max-w-screen-xl pt-20 pb-10 mx-auto">
      <div class="w-full mb-10 lg:w-1/4 md:w-1/3 md:mb-0">
        <router-link to="/">
          <img
            width="214"
            height="46"
            class="mx-auto md:w-2/3 w-60"
            alt="APEXX"
            src="/img/logo.webp"
          />
        </router-link>
        <div class="flex items-center justify-center mt-8">
          <a
            class="block w-[20px] mr-4 cursor-pointer"
            :href="company_info.facebook.title"
            aria-label="Facebook"
            target="_blank"
            @mouseenter="social_hover = 'facebook'"
            @mouseleave="social_hover = ''"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                :class="
                  social_hover == 'facebook' ? 'fill-primary' : 'fill-white'
                "
                class="transition-all duration-300"
                d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
              />
            </svg>
          </a>
          <a
            class="block w-[20px] mr-4 cursor-pointer"
            :href="company_info.instagram.title"
            aria-label="Instagram"
            target="_blank"
            @mouseenter="social_hover = 'instagram'"
            @mouseleave="social_hover = ''"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                :class="
                  social_hover == 'instagram' ? 'fill-primary' : 'fill-white'
                "
                class="transition-all duration-300"
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              />
            </svg>
          </a>
          <!-- <a
            class="block w-[24px] mr-4 cursor-pointer"
            :href="company_info.LINE.title"
            aria-label="LINE"
            target="_blank"
            @mouseenter="social_hover = 'line'"
            @mouseleave="social_hover = ''"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
              <path
                :class="social_hover == 'line' ? 'fill-primary' : 'fill-white'"
                class="transition-all duration-300"
                d="M 9 4 C 6.24 4 4 6.24 4 9 L 4 41 C 4 43.76 6.24 46 9 46 L 41 46 C 43.76 46 46 43.76 46 41 L 46 9 C 46 6.24 43.76 4 41 4 L 9 4 z M 25 11 C 33.27 11 40 16.359219 40 22.949219 C 40 25.579219 38.959297 27.960781 36.779297 30.300781 C 35.209297 32.080781 32.660547 34.040156 30.310547 35.660156 C 27.960547 37.260156 25.8 38.519609 25 38.849609 C 24.68 38.979609 24.44 39.039062 24.25 39.039062 C 23.59 39.039062 23.649219 38.340781 23.699219 38.050781 C 23.739219 37.830781 23.919922 36.789063 23.919922 36.789062 C 23.969922 36.419063 24.019141 35.830937 23.869141 35.460938 C 23.699141 35.050938 23.029062 34.840234 22.539062 34.740234 C 15.339063 33.800234 10 28.849219 10 22.949219 C 10 16.359219 16.73 11 25 11 z M 23.992188 18.998047 C 23.488379 19.007393 23 19.391875 23 20 L 23 26 C 23 26.552 23.448 27 24 27 C 24.552 27 25 26.552 25 26 L 25 23.121094 L 27.185547 26.580078 C 27.751547 27.372078 29 26.973 29 26 L 29 20 C 29 19.448 28.552 19 28 19 C 27.448 19 27 19.448 27 20 L 27 23 L 24.814453 19.419922 C 24.602203 19.122922 24.294473 18.992439 23.992188 18.998047 z M 15 19 C 14.448 19 14 19.448 14 20 L 14 26 C 14 26.552 14.448 27 15 27 L 18 27 C 18.552 27 19 26.552 19 26 C 19 25.448 18.552 25 18 25 L 16 25 L 16 20 C 16 19.448 15.552 19 15 19 z M 21 19 C 20.448 19 20 19.448 20 20 L 20 26 C 20 26.552 20.448 27 21 27 C 21.552 27 22 26.552 22 26 L 22 20 C 22 19.448 21.552 19 21 19 z M 31 19 C 30.448 19 30 19.448 30 20 L 30 26 C 30 26.552 30.448 27 31 27 L 34 27 C 34.552 27 35 26.552 35 26 C 35 25.448 34.552 25 34 25 L 32 25 L 32 24 L 34 24 C 34.553 24 35 23.552 35 23 C 35 22.448 34.553 22 34 22 L 32 22 L 32 21 L 34 21 C 34.552 21 35 20.552 35 20 C 35 19.448 34.552 19 34 19 L 31 19 z"
              />
            </svg>
          </a> -->
        </div>
      </div>
      <div class="hidden w-1/4 lg:block"></div>
      <div class="w-full mb-10 lg:w-1/4 md:w-1/3 md:mb-0">
        <div class="w-2/3 mx-auto text-center md:text-left">
          <h3 class="mb-5 text-2xl font-bold text-primary">CONTACT INFO</h3>
          <p
            class="mb-2 text-sm text-white"
            v-html="company_info.company_address.title"
          ></p>
          <p
            class="mb-2 text-sm text-white"
            v-html="company_info.company_phone.title"
          ></p>
          <p
            class="mb-2 text-sm text-white"
            v-html="company_info.company_email.title"
          ></p>
        </div>
      </div>
      <div class="w-full lg:w-1/4 md:w-1/3">
        <div class="w-2/3 mx-auto text-center md:text-left">
          <h3 class="mb-5 text-2xl font-bold text-primary">SITE MAP</h3>
          <p
            class="mb-2 text-sm text-white"
            v-for="item in menu_list"
            :key="item.id"
          >
            <router-link :to="item.url">
              {{ item.title }}
            </router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="w-full max-w-screen-xl px-4 py-6 mx-auto xl:px-0">
      <p class="text-xs text-center text-white">
        Copyright © 2023 APEXX Inc. 著作權所有，並保留一切權利。
        <router-link class="text-primary" to="/return_policy"
          >退/換貨政策</router-link
        >
        ，網頁設計：<a
          class="text-primary"
          target="_blank"
          href="https://www.yongxin-design.com"
          >泳欣事業有限公司</a
        >
      </p>
    </div>
  </footer>
</template>

<script>
import { GetPageData } from "@/api/footer";
export default {
  name: "MainFooter",
  data() {
    return {
      social_hover: "",
      menu_list: [
        {
          title: "關於我們",
          sub_title: "ABOUT",
          url: "/about",
        },
        {
          title: "商品列表",
          sub_title: "PRODUCTS",
          url: "/products",
        },
        {
          title: "影音相簿",
          sub_title: "VIDEOS",
          url: "/video/list",
        },
        {
          title: "最新消息",
          sub_title: "NEWS",
          url: "/news/list",
        },
        {
          title: "客服中心",
          sub_title: "SUPPORT",
          url: "/support",
        },
        {
          title: "經銷據點",
          sub_title: "DEALERS",
          url: "/dealers",
        },
      ],
      company_info: {
        company_name: "",
        company_address: "",
        company_phone: "",
        company_email: "",
        facebook: "",
        instagram: "",
        LINE: "",
      },
    };
  },
  created() {
    GetPageData().then((res) => {
      this.company_info = res.data;
    });
  },
};
</script>
