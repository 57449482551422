import gsap from 'gsap';

export class loading_animation {
  constructor(el) {
    this.el = el;
    this.path = el.querySelectorAll('.cls-1');
    this.timeline = null;
  }

  reset() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = null;

    this.path.forEach((item, item_index) => {
      gsap.set(item, { opacity: 1 });
      this.path[item_index].style.StrokeDasharray = item.getTotalLength();
      this.path[item_index].style.StrokeDashoffset = item.getTotalLength();
    });
  }

  open() {
    gsap.set(this.el, { x: '-100%' });
    this.reset();
    this.timeline = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.4,
    });

    this.path.forEach((item) => {
      item.style.StrokeDasharray = item.getTotalLength();
      item.style.StrokeDashoffset = item.getTotalLength();
    });

    this.timeline.set(this.path, { opacity: 1 }, 'set');

    this.path.forEach((item, item_index) => {
      this.timeline.set(
        item,
        { strokeDasharray: item.getTotalLength() + 'px' },
        'set'
      );
      this.timeline.fromTo(
        item,
        {
          strokeDashoffset: item.getTotalLength(),
        },
        {
          strokeDashoffset: 0,
          duration: 1,
          delay: 0.2 * item_index,
        },
        'first'
      );
    });

    this.timeline.to(
      this.path,
      {
        opacity: 0,
      },
      'second'
    );
  }

  close() {
    gsap.set(this.el, { x: '0%' });
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = null;
  }
}
