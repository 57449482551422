<template>
  <section ref="Marquee" class="w-full overflow-hidden">
    <div
      data-marquee-box
      data-duration="8"
      :data-direction="direction"
      class="gsap_item line_box whitespace-nowrap"
    >
      <div
        data-marquee-item
        class="inline-block px-2 w-72 opacity-60"
        style="text-stroke: 1px white"
        v-for="item in 20"
        :key="item.id"
      >
        <img
          src="/img/marquee_logo.webp"
          width="272"
          height="60"
          alt="APEXX"
          class="block w-full"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { marquee } from '@/gsap/home/marquee';
export default {
  name: 'HomeMarquee',
  props: {
    direction: {
      type: String,
      default: 'left',
    },
  },
  data() {
    return {
      marquee,
    };
  },
  mounted() {
    this.marquee = new marquee(this.$refs.Marquee);
  },
};
</script>

<style>
.cls-1 {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
}
</style>
