<template>
  <div
    id="MainDialog"
    class="fixed top-0 left-0 right-[120%] bottom-0 bg-black bg-opacity-50 z-40 flex items-center justify-center overflow-hidden"
  >
    <div class="text_box w-96 py-16 bg-primary relative z-[41]">
      <button title="關閉訊息" class="absolute top-2 right-2" @click="Hide">
        <span class="text-white icon-close"></span>
      </button>
      <h4
        class="px-4 text-base font-bold text-center text-white"
        v-html="dialog.msg"
      ></h4>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
export default {
  name: 'MainDialog',
  data() {
    return {};
  },
  methods: {
    Show() {
      let tl = gsap.timeline();
      let main_el = document.querySelector('#MainDialog');
      let text_box = main_el.querySelector('.text_box');

      tl.set(main_el, {
        right: 0,
      });
      tl.fromTo(
        main_el,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.4,
        }
      );
      tl.fromTo(
        text_box,
        {
          scale: 0,
          transformOrigin: 'center',
        },
        {
          scale: 1,
          transformOrigin: 'center',
          duration: 0.3,
        }
      );
    },
    Hide() {
      this.$store.commit('SetMainDialog', { msg: '', action: false });
      let tl = gsap.timeline();
      let main_el = document.querySelector('#MainDialog');
      let text_box = main_el.querySelector('.text_box');

      tl.fromTo(
        text_box,
        {
          scale: 1,
          transformOrigin: 'center',
        },
        {
          scale: 0,
          transformOrigin: 'center',
          duration: 0.3,
        }
      );

      tl.fromTo(
        main_el,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 0.4,
        }
      );

      tl.set(main_el, {
        right: '100%',
      });
    },
  },
  watch: {
    status() {
      this.status ? this.Show() : '';
    },
  },
  mounted() {
    this.status ? this.Show() : '';
  },
  computed: {
    status() {
      return this.$store.state.main_dialog.status;
    },
    dialog() {
      return this.$store.state.main_dialog;
    },
  },
};
</script>
