<template>
  <main class="home">
    <BreadCrumb :path="breadcrumb" />
    <Carousel ref="Carousel" class="fixed top-0 left-0 z-10" />
    <div
      class="relative w-full pointer-events-none md:aspect-w-5 aspect-w-1 aspect-h-1 md:aspect-h-2"
    ></div>
    <div class="main_content relative z-[11] pt-10">
      <MainBg />
      <Marquee ref="Marquee01" direction="left" class="mb-10 md:mb-20" />
      <MainProduct01 ref="MainProduct01" />
      <MainProduct02 ref="MainProduct02" />
      <ProductCategory />
      <Marquee ref="Marquee02" direction="right" class="my-10 md:my-20" />
      <About />
      <News ref="News" />
      <Social />
      <MainContact class="mt-20" />
    </div>
  </main>
</template>

<script>
import MainBg from '@/components/MainBg.vue';
import Carousel from '@/components/Home/Carousel.vue';
import About from '@/components/Home/About.vue';
import MainProduct01 from '@/components/Home/MainProduct01.vue';
import MainProduct02 from '@/components/Home/MainProduct02.vue';
import Marquee from '@/components/Home/Marquee.vue';
import ProductCategory from '@/components/Home/ProductCategory.vue';
import News from '@/components/Home/News.vue';
import Social from '@/components/Home/Social.vue';
import MainContact from '@/components/MainContact.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';
import { GetMetaData } from '@/common/meta';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'HomeView',
  data() {
    return {
      carousel_height: 0,
      meta_data: null,
      breadcrumb: [
        {
          link: '/',
          title: '首頁',
        },
      ],
    };
  },
  components: {
    MainBg,
    Carousel,
    About,
    MainProduct01,
    MainProduct02,
    Marquee,
    ProductCategory,
    News,
    Social,
    MainContact,
    BreadCrumb,
  },
  methods: {
    PageInit() {
      this.meta_data = GetMetaData('官方網站', '', '');
      this.$nextTick(() => {
        this.$emit('load-image');
      });
    },
    SetGsap() {
      this.$refs.Carousel.SetGsap();
      this.$refs.MainProduct01.SetGsap();
      this.$refs.MainProduct02.SetGsap();
      this.$refs.News.SetGsap();
      this.$nextTick(() => {
        this.$PageReady('官方網站');
      });
    },
  },
  watch: {
    page_image_loaded() {
      this.page_image_loaded ? this.SetGsap() : '';
    },
    data_load_finish() {
      this.data_load_finish ? this.PageInit() : '';
    },
  },
  computed: {
    ...mapState(['page_image_loaded']),
    ...mapGetters(['data_load_finish']),
  },
  created() {
    this.data_load_finish ? this.PageInit() : '';
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
