import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router/index';
import {
  GetCarouselData,
  GetCommonColumnData,
  GetDealerData,
  GetNewsData,
  GetProductData,
  GetQuestionData,
  GetVideoData,
} from '@/api/page';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    main_menu: false,
    blur: false,
    order_search_dialog: false,
    page_image_loaded: false,
    main_loading: 0,
    shop_cart_dialog: false,
    shop_cart: [],
    main_dialog: {
      msg: '',
      status: false,
    },
    trade_result: {
      trade_data: null,
      products: null,
    },
    carousel_data: null,
    news_data: null,
    common_column_data: null,
    dealer_data: null,
    video_data: null,
    question_data: null,
    product_category_data: null,
    product_data: null,
    product_type_data: null,
    product_tag_data: null,
    product_vendor_data: null,
    body_text: '',
    first_loading: true,
  },
  getters: {
    data_load_finish(state) {
      return (
        state.news_data != null &&
        state.common_column_data != null &&
        state.dealer_data != null &&
        state.product_category_data != null &&
        state.product_data != null &&
        state.video_data != null &&
        state.question_data != null &&
        state.product_type_data != null &&
        state.product_tag_data != null &&
        state.product_vendor_data != null &&
        state.carousel_data != null
      );
    },
    get_common_column: (state) => (key) => {
      if (state.common_column_data == null) {
        return null;
      }
      const column_data = state.common_column_data.filter(
        (column) => column.column_name == key
      );
      return column_data.length > 0 ? column_data[0] : null;
    },
  },
  mutations: {
    SetBodyText(state) {
      let str = document.body.innerText;
      let uniqueChars = '';
      for (let i = 0; i < str.length; i++) {
        if (
          str.charAt(i) !== ' ' &&
          uniqueChars.indexOf(str.charAt(i)) === -1
        ) {
          uniqueChars += str[i];
        }
      }
      uniqueChars = uniqueChars.replace(/\s/g, '');
      state.body_text = [...new Set(uniqueChars)].join('');
    },
    SetFirstLoading(state, action) {
      state.first_loading = action;
    },
    SetMainMenu(state, action) {
      state.main_menu = action;
      state.blur = action;
    },
    SetOrderSearchDialog(state, action) {
      state.order_search_dialog = action;
      state.blur = action;
    },
    SetImageLoaded(state, action) {
      state.page_image_loaded = action;
    },
    SetLoading(state, action) {
      state.main_loading += action;
    },
    SetShopCartDialog(state, action) {
      state.shop_cart_dialog = action;
    },
    SetShopCart(state, val) {
      state.shop_cart = val;
    },
    SetMainDialog(state, { msg, action }) {
      state.main_dialog.msg = msg;
      state.main_dialog.status = action;
    },
    SaveOrderResult(state, trade_result) {
      state.trade_result = {
        trade_data: trade_result.trade_data,
        products: trade_result.products,
      };
    },
    SetStateData(state, { key, val }) {
      state[key] = val;
    },
  },
  actions: {
    GetCarouselData(state) {
      GetCarouselData().then((res) => {
        if (router.history.current.name == 'home') {
          let pc_image_link = document.createElement('link');
          pc_image_link.as = 'image';
          pc_image_link.rel = 'preload';
          pc_image_link.href = res.data[0].image_pc;

          let mb_image_link = document.createElement('link');
          mb_image_link.as = 'image';
          mb_image_link.rel = 'preload';
          mb_image_link.href = res.data[0].image_mb;
          document.head.appendChild(pc_image_link);
          document.head.appendChild(mb_image_link);
        }

        state.commit('SetStateData', {
          key: 'carousel_data',
          val: res.data,
        });
      });
    },
    GetCommonColumnData(state) {
      GetCommonColumnData().then((res) => {
        state.commit('SetStateData', {
          key: 'common_column_data',
          val: res.data,
        });
      });
    },
    GetDealerData(state) {
      GetDealerData().then((res) => {
        state.commit('SetStateData', {
          key: 'dealer_data',
          val: res.data,
        });
      });
    },
    GetNewsData(state) {
      GetNewsData().then((res) => {
        state.commit('SetStateData', {
          key: 'news_data',
          val: res.data,
        });
      });
    },
    GetProductData(state) {
      GetProductData().then((res) => {
        state.commit('SetStateData', {
          key: 'product_category_data',
          val: res.data.category,
        });

        const products = res.data.products.products.filter(
          (item) => item.available == '1'
        );
        state.commit('SetStateData', {
          key: 'product_data',
          val: products,
        });
        state.commit('SetStateData', {
          key: 'product_type_data',
          val: res.data.products.product_type,
        });
        state.commit('SetStateData', {
          key: 'product_tag_data',
          val: res.data.products.tags,
        });
        state.commit('SetStateData', {
          key: 'product_vendor_data',
          val: res.data.products.vendors,
        });
      });
    },
    GetQuestionData(state) {
      GetQuestionData().then((res) => {
        state.commit('SetStateData', {
          key: 'question_data',
          val: res.data,
        });
      });
    },
    GetVideoData(state) {
      GetVideoData().then((res) => {
        state.commit('SetStateData', {
          key: 'video_data',
          val: res.data,
        });
      });
    },
  },
  modules: {},
});
